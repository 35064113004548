



































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import countriesList from "@/assets/data/countries.json";

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: "list",
    },
    search: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root }) {
    const state = reactive({
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        { value: "image", text: "", width: 1 },
        { value: "lastName", text: root.$tc("layout.misc.data") },
        { value: "institution", text: root.$tc("layout.misc.institution") },
        { value: "city", text: root.$tc("layout.misc.city") },
        { value: "country", text: root.$tc("layout.misc.country") },
        {
          value: "languageVersion",
          text: root.$tc("layout.misc.languageVersion"),
        },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: "",
      searchTimeout: undefined as any,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("lecturer", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            search: state.search || undefined,
          },
        })
        .then(({ data: { lecturers, total } }) => {
          state.empty = false;
          state.items = lecturers.map((lecturer: any, index: number) => ({
            id: index,
            ...lecturer,
          }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`lecturer/${item.id}`)
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    watch(
      () => props.search,
      (newSearch) => {
        state.search = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/lecturer/`
    );

    const copyItem = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`lecturer/${id}`)
        .then(({ data: { lecturer } }) => {
          state.loading = true;

          const lastName = prompt(
            root.$tc("panel.globalTools.lecturer.enterNewLecturerName"),
            `${lecturer.lastName} (kopia)`
          );

          if (lastName) {
            axiosInstance
              .post("lecturer", {
                firstName: lecturer.firstName,
                lastName: lastName,
                languageVersion: lecturer.languageVersion || undefined,
                description: lecturer.description || undefined,
                degree: lecturer.degree || undefined,
                city: lecturer.city || undefined,
                country: lecturer.country || undefined,
                institution: lecturer.institution || undefined,
                phoneNumber: lecturer.phoneNumber || undefined,
                email: lecturer.email || undefined,
                image: lecturer.image?.id || undefined,
              })
              .then(fetchData)
              .catch((error) => {
                console.log(error);
                alert(root.$tc("panel.globalTools.lecturer.newLecturerError"));
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            state.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
        });
    };

    return { state, fetchData, deleteItem, filesUrl, countriesList, copyItem };
  },
});
